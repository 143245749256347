import { HomeView, IntroView, ContactView } from '../views';

export const VIEWS = {
  home: {
    path: '/',
    title: 'Ôn phôn',
    Component: (props) => <HomeView {...props} />
  },
  intro: {
    path: '/meuturi',
    title: 'Meuturi',
    Component: (props) => <IntroView {...props} />
  },
  contact: {
    path: '/meuhubong',
    title: 'Meuhubông',
    Component: (props) => <ContactView {...props} />
  }
};
