import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/Diversity3';
import MenuIcon from '@mui/icons-material/Menu';

import { VIEWS } from '../../routes/helper';

import './AppBar.css';

const NavBar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" className="container">
      <Container maxWidth="lg">
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            position: 'relative'
          }}
          disableGutters>
          <IconButton
            size="large"
            aria-label="navigation menu"
            aria-controls="menu-navbar"
            aria-haspopup="true"
            color="inherit"
            onClick={handleOpenNavMenu}
            sx={{
              position: 'absolute',
              display: { xs: 'block', sm: 'none' },
              ml: { xs: -0.8, md: 0 }
            }}>
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' }
            }}>
            {Object.keys(VIEWS).map((key) => (
              <MenuItem
                key={key}
                onClick={() => {
                  navigate(VIEWS[key].path);
                  handleCloseNavMenu();
                }}>
                <Typography textAlign="center">{VIEWS[key].title}</Typography>
              </MenuItem>
            ))}
          </Menu>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: 'flex-start' },
              px: { xs: 0, sm: 3 },
              textShadow: `1px 1px 2px salmon`,
              userSelect: 'none',
              flexGrow: { xs: 1, sm: 0 }
            }}>
            <PeopleIcon
              sx={{
                color: theme.palette.grey[300],
                borderRadius: 100,
                boxShadow: `1px 1px 3px salmon`,
                fontSize: 38,
                mr: 0.2
              }}
            />
            <div className="logo">
              <Typography
                variant="caption"
                component="p"
                noWrap
                sx={{
                  position: 'absolute',
                  top: '13px',
                  left: 0,
                  color: theme.palette.grey[300],
                  fontStyle: 'italic',
                  pr: 1
                }}>
                buët
              </Typography>
              <Typography
                variant="h5"
                noWrap
                sx={{
                  position: 'absolute',
                  top: '20px',
                  left: 0,
                  mr: { sx: 0, sm: 1 },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  color: theme.palette.grey[300],
                  textDecoration: 'none'
                }}>
                SabohMasa
              </Typography>
            </div>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexGrow: 1,
              justifyContent: 'flex-end'
            }}>
            {Object.keys(VIEWS).map((key) => (
              <Button
                key={key}
                sx={{
                  mb: 0.3,
                  mr: 2,
                  color: 'inherit'
                }}
                onClick={() => navigate(VIEWS[key].path)}>
                {VIEWS[key].title}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
