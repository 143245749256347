import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import ContactForm from '../../components/ContactForm';

function Contact() {
  return (
    <Container sx={{ textAlign: 'left' }}>
      <Typography sx={{ mb: 1 }} variant="h5" component="h2">
        Meuhubông ngon kamoë
      </Typography>
      <Typography variant="body1" component="p">
        Teurimong geunaséh ka neusaweuë website SabohMasa. Untôk neumeuhubông ngon kamoë, jeuet
        neungui cara-cara lagèë nyang ka meutuléh dimuyup nyoë:
      </Typography>
      <Typography sx={{ mt: 2 }} variant="subtitle2" component="p">
        Kirém peusan rot WhatsApp (WA):
      </Typography>
      <Typography variant="body1" component="address">
        +1 (xxx) xxx-xxxx
      </Typography>
      <Typography sx={{ mt: 2 }} variant="subtitle2" component="p">
        Kirém peusan rot formulir nyoë:
      </Typography>
      <ContactForm />
    </Container>
  );
}

export default Contact;
