import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import './HomeView.css';

function Home() {
  return (
    <>
      <Typography
        variant="h4"
        component="h1"
        sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
        Kontès Tulak Pemilu Indôn 2024
      </Typography>
      <Container maxWidth="md" sx={{ mt: { sm: 0, md: 3 }, mb: 2 }}>
        <div className="embed-container">
          <LiteYouTubeEmbed id="rItxle3w_cA" title="Atjèh Boikot Pemilu" />
        </div>
      </Container>
      <Typography variant="h6" component="h1" sx={{ display: { sm: 'block', md: 'none' } }}>
        Kontès Tulak Pemilu 2024
      </Typography>
    </>
  );
}

export default Home;
