import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import Beundira from './Beundira';

function Footer() {
  const theme = useTheme();

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: theme.palette.grey[100], color: theme.palette.text.secondary }}>
      <Container
        maxWidth="lg"
        sx={{ p: 1, userSelect: 'none', textAlign: { sx: 'center', sm: 'right' } }}>
        <Typography variant="body2" component="p">
          Copyright © <b>SabohMasa</b> 2023
          <Beundira
            sx={{ pl: '8px', pr: { xs: 0, sm: 3 }, mb: '-6px' }}
            width="30px"
            height="20px"
            viewBox="0 0 30 20"
          />
        </Typography>
      </Container>
    </AppBar>
  );
}

export default Footer;
