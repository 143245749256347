import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';

import AppBar from '../../components/AppBar';
import Footer from '../../components/Footer';

import './ViewContainer.css';

function ViewContainer() {
  return (
    <div className="view-container">
      <AppBar />
      <div className="body-container">
        <div className="content-container">
          <Container maxWidth="lg" sx={{ p: 1, py: 4 }}>
            <Outlet />
          </Container>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ViewContainer;
