import SvgIcon from '@mui/material/SvgIcon';

export default function Beundira(props) {
  return (
    <SvgIcon {...props} width="30px" height="20px" viewBox="0 0 30 20">
      <rect
        x="0"
        y="0"
        width="30"
        height="20"
        stroke="none"
        fill="rgb(79.215686%,6.666667%,10.196078%)"
        fillOpacity={1}
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity={1}
        d="M 0 16.800781 L 30 16.800781 L 30 18.800781 L 0 18.800781 Z M 0 16.800781 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity={1}
        d="M 0 1.199219 L 30 1.199219 L 30 3.199219 L 0 3.199219 Z M 0 1.199219 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity={1}
        d="M 0 17.199219 L 30 17.199219 L 30 18.398438 L 0 18.398438 Z M 0 17.199219 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity={1}
        d="M 0 1.601562 L 30 1.601562 L 30 2.800781 L 0 2.800781 Z M 0 1.601562 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity={1}
        d="M 16.246094 8.167969 C 15.503906 7.421875 14.382812 7.195312 13.410156 7.597656 C 12.433594 7.996094 11.800781 8.945312 11.800781 10 C 11.800781 11.054688 12.433594 12.003906 13.410156 12.402344 C 14.382812 12.804688 15.503906 12.578125 16.246094 11.832031 C 15.4375 13.042969 13.933594 13.578125 12.542969 13.15625 C 11.152344 12.734375 10.199219 11.453125 10.199219 10 C 10.199219 8.546875 11.152344 7.265625 12.542969 6.84375 C 13.933594 6.421875 15.4375 6.957031 16.246094 8.167969 Z M 16.246094 8.167969 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity={1}
        d="M 18.6875 8.191406 L 17.570312 9.730469 L 18.339844 10.289062 Z M 18.6875 8.191406 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity={1}
        d="M 18.6875 8.191406 L 18.6875 10.09375 L 17.738281 10.09375 Z M 18.6875 8.191406 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity={1}
        d="M 20 10 L 18.191406 9.414062 L 17.898438 10.316406 Z M 20 10 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity={1}
        d="M 20 10 L 18.191406 10.585938 L 17.898438 9.683594 Z M 20 10 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity={1}
        d="M 16.5625 8.882812 L 17.679688 10.421875 L 18.449219 9.863281 Z M 16.5625 8.882812 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity={1}
        d="M 16.5625 8.882812 L 18.371094 9.46875 L 18.078125 10.375 Z M 16.5625 8.882812 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity={1}
        d="M 18.6875 11.808594 L 18.6875 9.90625 L 17.738281 9.90625 Z M 18.6875 11.808594 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity={1}
        d="M 18.6875 11.808594 L 17.570312 10.269531 L 18.339844 9.710938 Z M 18.6875 11.808594 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity={1}
        d="M 16.5625 11.117188 L 18.371094 10.53125 L 18.078125 9.625 Z M 16.5625 11.117188 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity={1}
        d="M 16.5625 11.117188 L 17.679688 9.578125 L 18.449219 10.136719 Z M 16.5625 11.117188 "
      />
    </SvgIcon>
  );
}
