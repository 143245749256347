import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { ViewContainer } from '../views';

import { VIEWS } from './helper';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ViewContainer />}>
        {Object.keys(VIEWS).map((key) => {
          const Component = VIEWS[key].Component;
          return <Route key={key} path={VIEWS[key].path} element={<Component />} />;
        })}
      </Route>
    </Routes>
  );
}

export default AppRoutes;
