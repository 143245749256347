import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

var regName = /^[a-zA-Z]+[ ?[a-zA-Z]+]?$/;
var regEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

function ContactForm() {
  const theme = useTheme();
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [messageError, setMessageError] = React.useState(false);

  return (
    <form name="contact" method="post">
      <input type="hidden" name="form-name" value="contact" />
      <Stack maxWidth="sm">
        <TextField
          type="text"
          required
          id="name"
          label="Nan droë"
          placeholder="pasoë nan droë"
          margin="dense"
          value={name}
          error={nameError}
          onChange={(ev) => {
            setName(ev.target.value);
            if (nameError && ev.target.value) {
              setNameError(false);
            }
          }}
          onBlur={() => {
            setName(name.trim());
            if (!name.trim().length || !regName.test(name.trim())) setNameError(true);
          }}
          helperText={
            nameError
              ? !name.length
                ? 'Nan droë han jeuët soh.'
                : 'Nan droë hana betôi'
              : undefined
          }
        />
        <TextField
          type="email"
          required
          id="email"
          name="email"
          label="Email droë"
          placeholder="pasoë email droë"
          margin="dense"
          value={email}
          onChange={(ev) => {
            setEmail(ev.target.value);
            if (emailError && ev.target.value) {
              setEmailError(false);
            }
          }}
          onBlur={() => {
            setEmail(email.trim());
            if (!email.trim().length || !regEmail.test(email.trim())) setEmailError(true);
          }}
          helperText={
            emailError
              ? email.length
                ? 'Email droë hana beutôi'
                : 'Email droë han jeuët soh.'
              : undefined
          }
          error={emailError}
        />
        <div style={{ position: 'relative' }}>
          <Typography
            sx={{
              position: 'absolute',
              bottom: '8px',
              right: '8px'
            }}
            color={theme.palette.grey[600]}
            variant="caption"
            component="p">{`${message.length}/400`}</Typography>
          <TextField
            type="text"
            multiline
            fullWidth
            minRows={5}
            required
            id="message"
            name="message"
            label="Su-euë/peusan:"
            placeholder="pasoë su-eue/peusan"
            margin="dense"
            value={message}
            onChange={(ev) => {
              if (ev.target.value.length <= 400) {
                setMessage(ev.target.value);
              }
              if (messageError && ev.target.value) {
                setMessageError(false);
              }
            }}
            onBlur={() => {
              setMessage(message.trim());
              if (!message.trim().length) setMessageError(true);
            }}
            helperText={messageError ? 'Peusan droë han jeuët soh.' : undefined}
            error={messageError}
          />
        </div>
        <Button
          disabled={!name || !email || !message || nameError || emailError || messageError}
          variant="contained"
          color="primary"
          type="submit">
          Kirém
        </Button>
      </Stack>
    </form>
  );
}

export default ContactForm;
